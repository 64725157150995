import * as React from 'react';
import {Link} from 'gatsby';

import styled from '@emotion/styled';
import Layout from '../components/layout';
import Seo from '../components/seo';
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper';

// background images 
import HomeBG from '../images/bg/thornhill-plumbing-Residential-Commercial.jpg';
import SinkBG from '../images/bg/thornhill-plumbing-sink.jpg';

// services images
import TopQuality from '../images/home/thornhill-plumbing-2_opacity.jpg';
import Affordable from '../images/home/thornhill-plumbing-3_opacity.jpg';
import FreeQuotes from '../images/home/thornhill-plumbing-1_opacity.jpg';
import StyledLink from '../components/styledLink';

const ColorBar = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ColorBarGrey = styled(ColorBar)`
  background: var(--grey);
  margin: 64px auto 0 auto;
`;

const ColorBarBlue = styled(ColorBar)`
  background: var(--blue);
  margin: 0 auto;

  h1, p {
    color: white;
    text-align: center;
  }
`;

const Heading = styled.h1`
  color: var(--blue);
`;

const SubHeading = styled.h2`
  color: var(--blue);
`;

const FakeH1 = styled.p`
  font-size: 3rem;
  font-family: var(--font-heading);
  letter-spacing: -2.3px;
`;

const FakeHeading = styled.p`
  font-size: 2.2rem;
  text-align: center;
`;

const HeroImage = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
  background: url(${HomeBG}) no-repeat top left;
  background-size: cover;
  margin-bottom: 64px;
`;
const HeroImageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: white;
  background-color: rgba(0, 0, 0, .5);
`;

const ServicesRow = styled.div`
  margin: 0 auto;
  display: flex;
  flex-basis: 0;
  flex-direction: column;


  div {
    min-height: 225px;
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-fit: cover;
  }

  p {
    font-weight: bold;
    font-family: var(--font-heading);
    font-size: 2.6rem;
    text-align: center;

    @media (min-width: 890px) {
      font-size: 2.8rem;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ServicesRowBoxInnerWrapper = styled.div`
  color: white;
`;

const ServicesRowBoxA = styled.div`
  background: url(${TopQuality}) center;
`;
const ServicesRowBoxB = styled.div`
  background: url(${Affordable}) center;
`;
const ServicesRowBoxC = styled.div`
  background: url(${FreeQuotes}) center;
`;

const TLCContainer = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
  background: url(${SinkBG}) no-repeat center;
  background-size: cover;
  margin-bottom: 0;
`;
const TLCContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: white;
  background-color: rgba(0, 0, 0, .5);
`;

const IndexPage = () => (
  <Layout>
    <Seo title='Thornhill Plumbing'
         description='High quality plumbing and renovations. Licensed and Insured.' />

    <HeroImage>
      <HeroImageInnerContainer>
        <h1>Residential &amp; Commercial<br />Plumbing Services</h1>
        <FakeHeading>
          Over 20 Years of Experience.<br />Licensed &amp; Insured.
      </FakeHeading>
      </HeroImageInnerContainer>
    </HeroImage>

    <Heading>Residential, Commercial, Repairs &amp; Auger Services</Heading>
    <SiteWidthWrapper>
      <FakeH1 className='centerText'>Covid-19 Policy:</FakeH1>
      <p><strong>To Our Valued Customers:</strong> We’re still operating at 100% at this time. We’re extremely vigilant with our fight against Covid-19 and are taking precautions to ensure the safety of all our customers, suppliers, &amp; ourselves. We ask our customers to advise us ahead of time of any flu-like symptoms to ensure we can maintain a safe distance while we’re working in your home. Thank you from all of us here at Thornhill Plumbing and Renovations. We’re all in this together!</p>
    </SiteWidthWrapper>

    <SiteWidthWrapper>
      <SubHeading>Home &amp; Commercial Renovations</SubHeading>
      <p>For over 20 years we have been helping people with their home and commercial renovations. We are here to help you acquire the kitchen or bathroom of your dreams. If you are interested in a small update or a full renovation, feel free to give us a call to discuss your project.</p>
    </SiteWidthWrapper>

    <ColorBarGrey>
      <SiteWidthWrapper margin='3em auto 0 auto'>
        <ServicesRow>

          <ServicesRowBoxA>
            <ServicesRowBoxInnerWrapper>
              <p>Top Quality</p>
            </ServicesRowBoxInnerWrapper>
          </ServicesRowBoxA>

          <ServicesRowBoxB>
            <ServicesRowBoxInnerWrapper>
              <p>Affordable Pricing</p>
            </ServicesRowBoxInnerWrapper>
          </ServicesRowBoxB>

          <ServicesRowBoxC>
            <ServicesRowBoxInnerWrapper>
              <p>Free Quotes</p>
            </ServicesRowBoxInnerWrapper>
          </ServicesRowBoxC>

        </ServicesRow>

      </SiteWidthWrapper>
    </ColorBarGrey>

    <TLCContainer>
      <TLCContainerInner>
        <FakeH1>Every Home Needs TLC</FakeH1>

        {/* <FakeHeading>And you don’t need the headache of doing it yourself.<br />Call Thornhill Plumbing to get the flow on the go!</FakeHeading> */}
        <p className='centerText'>And you don’t need the headache of doing it yourself.<br />Call Thornhill Plumbing to get the flow on the go!</p>
          <Link to='/contact/' title='contact thornhill plumbing'>
            <StyledLink cta='Get A Quote' />
          </Link>

      </TLCContainerInner>
    </TLCContainer>

    <ColorBarBlue>
      <SiteWidthWrapper>
        <FakeH1>Remodeling, Replacing fixtures, etc</FakeH1>
        <p>Free estimates are provided for work. We are more than just a service call.</p>
      </SiteWidthWrapper>
    </ColorBarBlue>
  </Layout>
);

export default IndexPage;
