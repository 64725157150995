import * as React from 'react';
// import { Link } from "gatsby"
import styled from '@emotion/styled';

const ButtonWrapper = styled.button`

  position: relative;
  display: inline-block;
  font-family: var(--font-heading);
  margin-top: 25px;
  padding: 0 20px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: black;
  font-size: 0.85rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  // background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);/
  // background-size: 400%;
  border-radius: 30px;
  background: white;
  border: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const StyledLink = props => (
    <>
    {props.anchor ? (
        <ButtonWrapper
            aria-label={props.label}
            className='anchor'
            // as="a"
            // href={props.href}
        >
        {props.cta}
      </ButtonWrapper>
    ) : (
        <ButtonWrapper aria-label={props.label} type={props.type}>
        {props.cta}
      </ButtonWrapper>
    )}
  </>
);

export default StyledLink;